<template>
  <v-app>
    <v-dialog v-model="showDialog" max-width="1000px">
      <v-card>
        <v-card-title>
          Confirmación
        </v-card-title>
        <v-card-text>
          <div class="row">
              <div class="col pb-0 pt-0 mb-0 mt-0" style="height: 3rem">
                  <v-checkbox v-model="validArchiveCheck1" label="Al redespachar una orden, el sistema CANCELARÁ la orden relacionada si es que no se ha ENTREGADO A TRANSPORTE."></v-checkbox>
              </div>                    
          </div>
          <div class="row">
              <div class="col pb-0 pt-0 mb-0 mt-0" style="height: 3rem">
                  <v-checkbox v-model="validArchiveCheck2" label="Esta acción no es reversible, una vez que presione GUARDAR la CANCELACIÓN de la orden no puede revertirse."></v-checkbox>
              </div>                    
          </div>            
          <div class="row">
              <div class="col pb-0 pt-0 mb-3 mt-0" style="height: 3rem">
                  <v-checkbox v-model="validArchiveCheck3" label="Al CANCELAR una orden que NO ha sido PREPARADA se devolverá al inventario disponible el stock de los productos de la orden."></v-checkbox>
              </div>                    
          </div>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="cancelAction" dark color="pink" >
                Cancelar
            </v-btn>
            <v-btn color="success" class="btn-starkons-primary" @click="confirmCreateOrderOr" :disabled="!validArchiveCheck1 || !validArchiveCheck2 || !validArchiveCheck3">
                Crear orden
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  data(){
    return {
      showDialog: false,
      order: {},
      validArchiveCheck1: false,
      validArchiveCheck2: false,
      validArchiveCheck3: false
    }
  },
  created() {
    this.$parent.$on('openDialogConfirmCreateOrderOr', (order) => {
      this.showDialog = true;
      this.order = order;
      this.validArchiveCheck1 = false;
      this.validArchiveCheck2 = false;
      this.validArchiveCheck3 = false;
    });
  },
  methods: {
    openDialog() {
      this.showDialog = true;      
    },
    cancelAction() {
      this.showDialog = false;
      this.$emit('cancel-create-order-or');
    },
    confirmAction() {
      this.showDialog = false;
    },
    confirmCreateOrderOr() {
          this.$emit('confirm-create-order-or');
          this.showDialog = false;
   
    }
  },

  watch:{
    showDialog:function(newValue, old){
      console.log('showDialog', newValue);
      if(!newValue){
        this.$emit('cancel-create-order-or');
      }
    }
  }


}
</script>