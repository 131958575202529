const comunas = [
  {
    "pais":"CL",
    "datos":
    [
      {
        region: "Metropolitana de Santiago",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
      {
        region: "De Tarapacá",
        comunas: [
          "Iquique",
          "Alto Hospicio",
          "Pozo Almonte",
          "Camiña",
          "Colchane",
          "Huara",
          "Pica"
        ]
      },
      {
        region: "De  Antofagasta",
        comunas: [
          "Antofagasta",
          "Mejillones",
          "Sierra Gorda",
          "Taltal",
          "Calama",
          "Ollagüe",
          "San Pedro de Atacama",
          "Tocopilla",
          "María Elena"
        ]
      },
      {
        region: "De Atacama",
        comunas: [
          "Copiapó",
          "Caldera",
          "Tierra Amarilla",
          "Chañaral",
          "Diego de Almagro",
          "Vallenar",
          "Alto del Carmen",
          "Freirina",
          "Huasco"
        ]
      },
      {
        region: "De Coquimbo",
        comunas: [
          "La Serena",
          "Coquimbo",
          "Andacollo",
          "La Higuera",
          "Paiguano",
          "Vicuña",
          "Illapel",
          "Canela",
          "Los Vilos",
          "Salamanca",
          "Ovalle",
          "Combarbalá",
          "Monte Patria",
          "Punitaqui",
          "Río Hurtado"
        ]
      },
      {
        region: "De Valparaíso",
        comunas: [
          "Valparaíso",
          "Casablanca",
          "Concón",
          "Juan Fernández",
          "Puchuncaví",
          "Quintero",
          "Viña del Mar",
          "Isla de Pascua",
          "Los Andes",
          "Calle Larga",
          "Rinconada",
          "San Esteban",
          "La Ligua",
          "Cabildo",
          "Papudo",
          "Petorca",
          "Zapallar",
          "Quillota",
          "Calera",
          "Hijuelas",
          "La Cruz",
          "Nogales",
          "San Antonio",
          "Algarrobo",
          "Cartagena",
          "El Quisco",
          "El Tabo",
          "Santo Domingo",
          "San Felipe",
          "Catemu",
          "Llay Llay",
          "Panquehue",
          "Putaendo",
          "Santa María",
          "Quilpué",
          "Limache",
          "Olmué",
          "Villa Alemana"
        ]
      },
      {
        region: "Del Libertador B. OHiggins",
        comunas: [
          "Rancagua",
          "Codegua",
          "Coinco",
          "Coltauco",
          "Doñihue",
          "Graneros",
          "Las Cabras",
          "Machalí",
          "Malloa",
          "San Francisco de Mostazal",
          "Olivar",
          "Peumo",
          "Pichidegua",
          "Quinta de Tilcoco",
          "Rengo",
          "Requínoa",
          "San Vicente de Tagua Tagua",
          "Pichilemu",
          "La Estrella",
          "Litueche",
          "Marchihue",
          "Navidad",
          "Paredones",
          "San Fernando",
          "Chépica",
          "Chimbarongo",
          "Lolol",
          "Nancagua",
          "Palmilla",
          "Peralillo",
          "Placilla",
          "Pumanque",
          "Santa Cruz"
        ]
      },
      {
        region: "Del Maule",
        comunas: [
          "Talca",
          "Constitución",
          "Curepto",
          "Empedrado",
          "Maule",
          "Pelarco",
          "Pencahue",
          "Río Claro",
          "San Clemente",
          "San Rafael",
          "Cauquenes",
          "Chanco",
          "Pelluhue",
          "Curicó",
          "Hualañé",
          "Licantén",
          "Molina",
          "Rauco",
          "Romeral",
          "Sagrada Familia",
          "Teno",
          "Vichuquén",
          "Linares",
          "Colbún",
          "Longaví",
          "Parral",
          "Retiro",
          "San Javier de Loncomilla",
          "Villa Alegre",
          "Yerbas Buenas"
        ]
      },
      {
        region: "Del Bíobío",
        comunas: [
          "Concepción",
          "Coronel",
          "Chiguayante",
          "Florida",
          "Hualqui",
          "Lota",
          "Penco",
          "San Pedro de la Paz",
          "Santa Juana",
          "Talcahuano",
          "Tomé",
          "Hualpén",
          "Lebu",
          "Arauco",
          "Cañete",
          "Contulmo",
          "Curanilahue",
          "Los Álamos",
          "Tirúa",
          "Los Ángeles",
          "Antuco",
          "Cabrero",
          "Laja",
          "Mulchén",
          "Nacimiento",
          "Negrete",
          "Quilaco",
          "Quilleco",
          "San Rosendo",
          "Santa Bárbara",
          "Tucapel",
          "Yumbel",
          "Alto Biobío"
        ]
      },
      {
        region: "De La Araucanía",
        comunas: [
          "Temuco",
          "Carahue",
          "Cunco",
          "Curarrehue",
          "Freire",
          "Galvarino",
          "Gorbea",
          "Lautaro",
          "Loncoche",
          "Melipeuco",
          "Nueva Imperial",
          "Padre las Casas",
          "Perquenco",
          "Pitrufquén",
          "Pucón",
          "Saavedra",
          "Teodoro Schmidt",
          "Toltén",
          "Vilcún",
          "Villarrica",
          "Cholchol",
          "Angol",
          "Collipulli",
          "Curacautín",
          "Ercilla",
          "Lonquimay",
          "Los Sauces",
          "Lumaco",
          "Purén",
          "Renaico",
          "Traiguén",
          "Victoria"
        ]
      },
      {
        region: "De Los Ríos",
        comunas: [
          "Valdivia",
          "Corral",
          "Lanco",
          "Los Lagos",
          "Máfil",
          "Mariquina",
          "Paillaco",
          "Panguipulli",
          "La Unión",
          "Futrono",
          "Lago Ranco",
          "Río Bueno"
        ]
      },
      {
        region: "De Los Lagos",
        comunas: [
          "Puerto Montt",
          "Calbuco",
          "Cochamó",
          "Fresia",
          "Frutillar",
          "Los Muermos",
          "Llanquihue",
          "Maullín",
          "Puerto Varas",
          "Castro",
          "Ancud",
          "Chonchi",
          "Curaco de Vélez",
          "Dalcahue",
          "Puqueldón",
          "Queilén",
          "Quellón",
          "Quemchi",
          "Quinchao",
          "Osorno",
          "Puerto Octay",
          "Purranque",
          "Puyehue",
          "Río Negro",
          "San Juan de la Costa",
          "San Pablo",
          "Chaitén",
          "Futaleufú",
          "Hualaihué",
          "Palena"
        ]
      },
      {
        region: "De Aisén del Gral. C. Ibáñez del Campo",
        comunas: [
          "Coyhaique",
          "Lago Verde",
          "Aisén",
          "Cisnes",
          "Guaitecas",
          "Cochrane",
          "O’Higgins",
          "Tortel",
          "Chile Chico",
          "Río Ibáñez"
        ]
      },
      {
        region: "De Magallanes y de La Antártica Chilena",
        comunas: [
          "Punta Arenas",
          "Laguna Blanca",
          "Río Verde",
          "San Gregorio",
          "Cabo de Hornos (Ex Navarino)",
          "Antártica",
          "Porvenir",
          "Primavera",
          "Timaukel",
          "Natales",
          "Torres del Paine"
        ]
      },
      {
        region: "De Arica y Parinacota",
        comunas: ["Arica", "Camarones", "Putre", "General Lagos"]
      },
      {
        region: "De Ñuble",
        comunas: [
          "Cobquecura",
          "Coelemu",
          "Ninhue",
          "Portezuelo",
          "Quirihue",
          "Ránquil",
          "Treguaco",
          "Bulnes",
          "Chillán Viejo",
          "Chillán",
          "El Carmen",
          "Pemuco",
          "Pinto",
          "Quillón",
          "San Ignacio",
          "Yungay",
          "Coihueco",
          "Ñiquén",
          "San Carlos",
          "San Fabián",
          "San Nicolás"
        ]
      },
      {
        region: "Los Lagos",
        comunas: [
          "Puerto Montt",
          "Calbuco",
          "Cochamó",
          "Fresia",
          "Frutillar",
          "Los Muermos",
          "Llanquihue",
          "Maullín",
          "Puerto Varas",
          "Castro",
          "Ancud",
          "Chonchi",
          "Curaco de Vélez",
          "Dalcahue",
          "Puqueldón",
          "Queilén",
          "Quellón",
          "Quemchi",
          "Quinchao",
          "Osorno",
          "Puerto Octay",
          "Purranque",
          "Puyehue",
          "Río Negro",
          "San Juan de la Costa",
          "San Pablo",
          "Chaitén",
          "Futaleufú",
          "Hualaihué",
          "Palena"
        ]
      },
      {
        region: "Aisén",
        comunas: [
          "Coihaique",
          "Lago Verde",
          "Aisén",
          "Cisnes",
          "Guaitecas",
          "Cochrane",
          "O’Higgins",
          "Tortel",
          "Chile Chico",
          "Río Ibáñez"
        ]
      },
      {
        region: "Araucanía",
        comunas: [
          "Temuco",
          "Carahue",
          "Cunco",
          "Curarrehue",
          "Freire",
          "Galvarino",
          "Gorbea",
          "Lautaro",
          "Loncoche",
          "Melipeuco",
          "Nueva Imperial",
          "Padre las Casas",
          "Perquenco",
          "Pitrufquén",
          "Pucón",
          "Saavedra",
          "Teodoro Schmidt",
          "Toltén",
          "Vilcún",
          "Villarrica",
          "Cholchol",
          "Angol",
          "Collipulli",
          "Curacautín",
          "Ercilla",
          "Lonquimay",
          "Los Sauces",
          "Lumaco",
          "Purén",
          "Renaico",
          "Traiguén",
          "Victoria"
        ]
      },
      {
        region: "Biobío",
        comunas: [
          "Concepción",
          "Coronel",
          "Chiguayante",
          "Florida",
          "Hualqui",
          "Lota",
          "Penco",
          "San Pedro de la Paz",
          "Santa Juana",
          "Talcahuano",
          "Tomé",
          "Hualpén",
          "Lebu",
          "Arauco",
          "Cañete",
          "Contulmo",
          "Curanilahue",
          "Los Álamos",
          "Tirúa",
          "Los Ángeles",
          "Antuco",
          "Cabrero",
          "Laja",
          "Mulchén",
          "Nacimiento",
          "Negrete",
          "Quilaco",
          "Quilleco",
          "San Rosendo",
          "Santa Bárbara",
          "Tucapel",
          "Yumbel",
          "Alto Biobío"
        ]
      },
      {
        region: "Libertador B. O'Higgins",
        comunas: [
          "Rancagua",
          "Codegua",
          "Coinco",
          "Coltauco",
          "Doñihue",
          "Graneros",
          "Las Cabras",
          "Machalí",
          "Malloa",
          "San Francisco de Mostazal",
          "Olivar",
          "Peumo",
          "Pichidegua",
          "Quinta de Tilcoco",
          "Rengo",
          "Requínoa",
          "San Vicente de Tagua Tagua",
          "Pichilemu",
          "La Estrella",
          "Litueche",
          "Marchihue",
          "Navidad",
          "Paredones",
          "San Fernando",
          "Chépica",
          "Chimbarongo",
          "Lolol",
          "Nancagua",
          "Palmilla",
          "Peralillo",
          "Placilla",
          "Pumanque",
          "Santa Cruz"
        ]
      },
      {
        region: "Los Ríos",
        comunas: [
          "Valdivia",
          "Corral",
          "Lanco",
          "Los Lagos",
          "Máfil",
          "Mariquina",
          "Paillaco",
          "Panguipulli",
          "La Unión",
          "Futrono",
          "Lago Ranco",
          "Río Bueno"
        ]
      },
      {
        region: "Magallanes",
        comunas: [
          "Punta Arenas",
          "Laguna Blanca",
          "Río Verde",
          "San Gregorio",
          "Cabo de Hornos (Ex Navarino)",
          "Antártica",
          "Porvenir",
          "Primavera",
          "Timaukel",
          "Natales",
          "Torres del Paine"
        ]
      },
      {
        region: "Maule",
        comunas: [
          "Talca",
          "Constitución",
          "Curepto",
          "Empedrado",
          "Maule",
          "Pelarco",
          "Pencahue",
          "Río Claro",
          "San Clemente",
          "San Rafael",
          "Cauquenes",
          "Chanco",
          "Pelluhue",
          "Curicó",
          "Hualañé",
          "Licantén",
          "Molina",
          "Rauco",
          "Romeral",
          "Sagrada Familia",
          "Teno",
          "Vichuquén",
          "Linares",
          "Colbún",
          "Longaví",
          "Parral",
          "Retiro",
          "San Javier de Loncomilla",
          "Villa Alegre",
          "Yerbas Buenas"
        ]
      },
      {
        region: "Metropolitana",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
      {
        region: " DE SANTIAGO",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
      {
        region: "Ñuble",
        comunas: [
          "Cobquecura",
          "Coelemu",
          "Ninhue",
          "Portezuelo",
          "Quirihue",
          "Ránquil",
          "Treguaco",
          "Bulnes",
          "Chillán Viejo",
          "Chillán",
          "El Carmen",
          "Pemuco",
          "Pinto",
          "Quillón",
          "San Ignacio",
          "Yungay",
          "Coihueco",
          "Ñiquén",
          "San Carlos",
          "San Fabián",
          "San Nicolás"
        ]
      },
      {
        region: "O'Higgins",
        comunas: [
          "Rancagua",
          "Codegua",
          "Coinco",
          "Coltauco",
          "Doñihue",
          "Graneros",
          "Las Cabras",
          "Machalí",
          "Malloa",
          "San Francisco de Mostazal",
          "Olivar",
          "Peumo",
          "Pichidegua",
          "Quinta de Tilcoco",
          "Rengo",
          "Requínoa",
          "San Vicente de Tagua Tagua",
          "Pichilemu",
          "La Estrella",
          "Litueche",
          "Marchihue",
          "Navidad",
          "Paredones",
          "San Fernando",
          "Chépica",
          "Chimbarongo",
          "Lolol",
          "Nancagua",
          "Palmilla",
          "Peralillo",
          "Placilla",
          "Pumanque",
          "Santa Cruz"
        ]
      },
      {
        region: "Region metropolitana",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
      {
        region: "RM (Metropolitana)",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
    ]
  },
  {
    "pais":"CRI",
    "datos":
    [
      {
         "region":"ESCAZU",
         "comunas":[
            "SAN RAFAEL",
            "ESCAZU",
            "SAN ANTONIO"
         ]
      },
      {
         "region":"HEREDIA",
         "comunas":[
            "HEREDIA",
            "SAN FRANCISCO",
            "ULLOA",
            "MERCEDES",
            "VARABLANCA"
         ]
      },
      {
         "region":"SAN PABLO",
         "comunas":[
            "RINCON DE SABANILLA",
            "SAN PABLO"
         ]
      },
      {
         "region":"CURRIDABAT",
         "comunas":[
            "CURRIDABAT",
            "GRANADILLA",
            "SANCHEZ",
            "TIRRASES"
         ]
      },
      {
         "region":"SAN JOSE",
         "comunas":[
            "ZAPOTE",
            "MERCED",
            "CARMEN",
            "SAN SEBASTIAN",
            "SAN FRANCISCO DE DOS RIOS",
            "HOSPITAL",
            "MATA REDONDA",
            "PAVAS",
            "URUCA",
            "HATILLO",
            "CATEDRAL"
         ]
      },
      {
         "region":"ASERRI",
         "comunas":[
            "ASERRI",
            "SALITRILLOS",
            "TARBACA",
            "SAN GABRIEL",
            "VUELTA DE JORCO",
            "MONTERREY",
            "LEGUA"
         ]
      },
      {
         "region":"DESAMPARADOS",
         "comunas":[
            "DESAMPARADOS",
            "GRAVILIAS",
            "SAN RAFAEL ARRIBA",
            "SAN RAFAEL ABAJO",
            "SAN JUAN DE DIOS",
            "SAN MIGUEL",
            "DAMAS",
            "LOS GUIDO",
            "SAN ANTONIO",
            "PATARRA",
            "ROSARIO",
            "FRAILES",
            "SAN CRISTOBAL"
         ]
      },
      {
         "region":"MORAVIA",
         "comunas":[
            "SAN VICENTE",
            "LA TRINIDAD",
            "SAN JERONIMO"
         ]
      },
      {
         "region":"ALAJUELA",
         "comunas":[
            "ALAJUELA",
            "SAN ANTONIO",
            "RIO SEGUNDO",
            "DESAMPARADOS",
            "SAN RAFAEL",
            "GUACIMA",
            "TAMBOR",
            "GARITA",
            "SABANILLA",
            "CARRIZAL",
            "TURRUCARES",
            "SAN ISIDRO",
            "SAN JOSE",
            "SARAPIQUI"
         ]
      },
      {
         "region":"ALAJUELITA",
         "comunas":[
            "ALAJUELITA",
            "CONCEPCION",
            "SAN ANTONIO",
            "SAN JOSECITO",
            "SAN FELIPE"
         ]
      },
      {
         "region":"TIBAS",
         "comunas":[
            "ANSELMO LLORENTE",
            "COLIMA",
            "CINCO ESQUINAS",
            "SAN JUAN",
            "LEON XIII"
         ]
      },
      {
         "region":"CARTAGO",
         "comunas":[
            "GUADALUPE (ARENILLA)",
            "OCCIDENTAL",
            "ORIENTAL",
            "AGUACALIENTE (SAN FRANCISCO)",
            "CARMEN",
            "SAN NICOLAS",
            "DULCE NOMBRE",
            "LLANO GRANDE",
            "CORRALILLO",
            "TIERRA BLANCA"
         ]
      },
      {
         "region":"MONTES DE OCA",
         "comunas":[
            "SAN PEDRO",
            "SAN RAFAEL",
            "MERCEDES",
            "SABANILLA"
         ]
      },
      {
         "region":"SANTA BARBARA",
         "comunas":[
            "SANTO DOMINGO",
            "JESUS",
            "SAN JUAN",
            "PURABA",
            "SANTA BARBARA",
            "SAN PEDRO"
         ]
      },
      {
         "region":"SAN RAFAEL",
         "comunas":[
            "SANTIAGO",
            "SAN JOSECITO",
            "SAN RAFAEL",
            "ANGELES",
            "CONCEPCION"
         ]
      },
      {
         "region":"GOICOECHEA",
         "comunas":[
            "CALLE BLANCOS",
            "GUADALUPE",
            "MATA DE PLATANO",
            "IPIS",
            "PURRAL",
            "SAN FRANCISCO",
            "RANCHO REDONDO"
         ]
      },
      {
         "region":"BARVA",
         "comunas":[
            "SAN PABLO",
            "SANTA LUCIA",
            "SAN PEDRO",
            "BARVA",
            "SAN ROQUE",
            "SAN JOSE DE LA MONTAÑA"
         ]
      },
      {
         "region":"MONTES DE OCA ",
         "comunas":[
            "SABANILLA",
            "SAN PEDRO"
         ]
      },
      {
         "region":"SANTO DOMINGO",
         "comunas":[
            "SANTA ROSA",
            "SANTO DOMINGO",
            "SAN MIGUEL",
            "TURES",
            "SAN VICENTE",
            "SANTO TOMAS",
            "PARA",
            "PARACITO"
         ]
      },
      {
         "region":"SAN ISIDRO",
         "comunas":[
            "SAN FRANCISCO",
            "SAN JOSE",
            "SAN ISIDRO",
            "CONCEPCION"
         ]
      },
      {
         "region":"EL GUARCO",
         "comunas":[
            "EL TEJAR",
            "SAN ISIDRO",
            "PATIO DE AGUA",
            "TOBOSI"
         ]
      },
      {
         "region":"LA UNION",
         "comunas":[
            "TRES RIOS",
            "SAN RAFAEL",
            "SAN DIEGO",
            "RIO AZUL",
            "CONCEPCION",
            "DULCE NOMBRE",
            "SAN JUAN",
            "SAN RAMON"
         ]
      },
      {
         "region":"VAZQUEZ DE CORONADO",
         "comunas":[
            "SAN ISIDRO",
            "PATALILLO",
            "SAN RAFAEL",
            "CASCAJAL",
            "DULCE NOMBRE DE JESUS"
         ]
      },
      {
         "region":"OREAMUNO",
         "comunas":[
            "SAN RAFAEL",
            "CIPRESES",
            "COT",
            "POTRERO CERRADO",
            "SANTA ROSA"
         ]
      },
      {
         "region":"SANTA ANA",
         "comunas":[
            "POZOS",
            "SANTA ANA",
            "SALITRAL",
            "PIEDADES",
            "URUCA",
            "BRASIL"
         ]
      },
      {
         "region":"FLORES",
         "comunas":[
            "SAN JOAQUIN",
            "BARRANTES",
            "LLORENTE"
         ]
      },
      {
         "region":"PARAISO",
         "comunas":[
            "PARAISO",
            "CACHI",
            "OROSI",
            "SANTIAGO"
         ]
      },
      {
         "region":"BELEN",
         "comunas":[
            "LA ASUNCION",
            "LA RIBERA",
            "SAN ANTONIO"
         ]
      },
      {
         "region":"MORA",
         "comunas":[
            "COLON",
            "JARIS",
            "QUITIRRISI",
            "GUAYABO",
            "TABARCIA",
            "PIEDRAS NEGRAS",
            "PICAGRES"
         ]
      },
      {
         "region":"ALVARADO",
         "comunas":[
            "CERVANTES",
            "PACAYAS",
            "CAPELLADES"
         ]
      },
      {
         "region":"ACOSTA",
         "comunas":[
            "CANGREJAL",
            "GUAITIL",
            "PALMICHAL",
            "SABANILLAS",
            "SAN IGNACIO"
         ]
      },
      {
         "region":"JIMENEZ",
         "comunas":[
            "JUAN VIÑAS",
            "TUCURRIQUE",
            "PEJIBAYE"
         ]
      },
      {
         "region":"DOTA",
         "comunas":[
            "COPEY",
            "JARDIN",
            "SANTA MARIA"
         ]
      },
      {
         "region":"TURRIALBA",
         "comunas":[
            "SANTA CRUZ",
            "CHIRRIPO",
            "SANTA ROSA",
            "LA ISABEL",
            "PAVONES",
            "LA SUIZA",
            "PERALTA",
            "SANTA TERESITA",
            "TAYUTIC",
            "TRES EQUIS",
            "TUIS",
            "TURRIALBA"
         ]
      },
      {
         "region":"PURISCAL",
         "comunas":[
            "BARBACOAS",
            "CANDELARITA",
            "CHIRES",
            "DESAMPARADITOS",
            "GRIFO ALTO",
            "MERCEDES SUR",
            "SAN ANTONIO",
            "SAN RAFAEL",
            "SANTIAGO"
         ]
      },
      {
         "region":"RIO CUARTO",
         "comunas":[
            "RIO CUARTO",
            "SANTA RITA",
            "SANTA ISABEL"
         ]
      },
      {
         "region":"SARAPIQUI",
         "comunas":[
            "CUREÑA",
            "LA VIRGEN",
            "LAS HORQUETAS",
            "LLANURAS DEL GASPAR",
            "PUERTO VIEJO"
         ]
      },
      {
         "region":"GRECIA",
         "comunas":[
            "BOLIVAR",
            "GRECIA",
            "PUENTE DE PIEDRA",
            "SAN ISIDRO",
            "SAN JOSE",
            "SAN ROQUE",
            "TACARES"
         ]
      },
      {
         "region":"GUATUSO",
         "comunas":[
            "BUENAVISTA",
            "COTE",
            "KATIRA",
            "SAN RAFAEL"
         ]
      },
      {
         "region":"LOS CHILES",
         "comunas":[
            "CAÑO NEGRO",
            "EL AMPARO",
            "LOS CHILES",
            "SAN JORGE"
         ]
      },
      {
         "region":"NARANJO",
         "comunas":[
            "CIRRI SUR",
            "EL ROSARIO",
            "NARANJO",
            "PALMITOS",
            "SAN JERONIMO",
            "SAN JOSE",
            "SAN JUAN",
            "SAN MIGUEL"
         ]
      },
      {
         "region":"PALMARES",
         "comunas":[
            "BUENOS AIRES",
            "CANDELARIA",
            "ESQUIPULAS",
            "LA GRANJA",
            "PALMARES",
            "SANTIAGO",
            "ZARAGOZA"
         ]
      },
      {
         "region":"SAN CARLOS",
         "comunas":[
            "CUTRIS",
            "FLORENCIA",
            "LA FORTUNA",
            "LA PALMERA",
            "MONTERREY",
            "PITAL",
            "POCOSOL",
            "VENADO",
            "VENECIA",
            "AGUAS ZARCAS",
            "BUENAVISTA",
            "LA TIGRA",
            "QUESADA"
         ]
      },
      {
         "region":"SAN RAMON",
         "comunas":[
            "ALFARO",
            "ANGELES",
            "CONCEPCION",
            "PEÑAS BLANCAS",
            "PIEDADES NORTE",
            "PIEDADES SUR",
            "SAN ISIDRO",
            "SAN JUAN",
            "SAN LORENZO",
            "SAN RAFAEL",
            "SAN RAMON",
            "SANTIAGO",
            "VOLIO",
            "ZAPOTAL"
         ]
      },
      {
         "region":"VALVERDE VEGA",
         "comunas":[
            "RODRIGUEZ",
            "SAN PEDRO",
            "SARCHI NORTE",
            "SARCHI SUR",
            "TORO AMARILLO"
         ]
      },
      {
         "region":"ZARCERO",
         "comunas":[
            "BRISAS",
            "GUADALUPE",
            "LAGUNA",
            "PALMIRA",
            "TAPEZCO",
            "ZAPOTE",
            "ZARCERO"
         ]
      },
      {
         "region":"TURRUBARES",
         "comunas":[
            "CARARA",
            "SAN JUAN DE MATA",
            "SAN LUIS",
            "SAN PABLO",
            "SAN PEDRO"
         ]
      },
      {
         "region":"ATENAS",
         "comunas":[
            "ATENAS",
            "CONCEPCION",
            "ESCOBAL",
            "JESUS",
            "MERCEDES",
            "SAN ISIDRO",
            "SAN JOSE",
            "SANTA EULALIA"
         ]
      },
      {
         "region":"GARABITO",
         "comuna":[
            "TARCOLES",
            "JACO"
         ]
      },
      {
         "region":"OROTINA",
         "comunas":[
            "COYOLAR",
            "EL MASTATE",
            "HACIENDA VIEJA",
            "LA CEIBA",
            "OROTINA"
         ]
      },
      {
         "region":"POAS",
         "comunas":[
            "SABANA REDONDA",
            "CARRILLOS",
            "SAN JUAN",
            "SAN PEDRO",
            "SAN RAFAEL"
         ]
      },
      {
         "region":"SAN MATEO",
         "comunas":[
            "DESMONTE",
            "JESUS MARIA",
            "LABRADOR",
            "SAN MATEO"
         ]
      },
      {
         "region":"ABANGARES",
         "comunas":[
            "COLORADO",
            "LAS JUNTAS",
            "SAN JUAN",
            "SIERRA"
         ]
      },
      {
         "region":"CAÑAS",
         "comunas":[
            "POROZAL",
            "BEBEDERO",
            "CAÑAS",
            "PALMIRA",
            "SAN MIGUEL"
         ]
      },
      {
         "region":"ESPARZA",
         "comunas":[
            "CALDERA",
            "ESPIRITU SANTO",
            "MACACONA",
            "SAN JERONIMO",
            "SAN JUAN GRANDE",
            "SAN RAFAEL"
         ]
      },
      {
         "region":"MONTES DE ORO",
         "comunas":[
            "LA UNION",
            "MIRAMAR",
            "SAN ISIDRO"
         ]
      },
      {
         "region":"PUNTARENAS",
         "comunas":[
            "ACAPULCO",
            "ARANCIBIA",
            "BARRANCA",
            "CHACARITA",
            "CHIRA",
            "CHOMES",
            "COBANO",
            "EL ROBLE",
            "GUACIMAL",
            "ISLA DEL COCO",
            "LEPANTO",
            "MANZANILLO",
            "MONTE VERDE",
            "PAQUERA",
            "PITAHAYA",
            "PUNTARENAS"
         ]
      },
      {
         "region":"PUNTARENAS ",
         "comunas":[
            "BARRANCA",
            "EL ROBLE"
         ]
      },
      {
         "region":"UPALA",
         "comunas":[
            "AGUAS CLARAS",
            "BIJAGUA",
            "CANALETE",
            "DELICIAS",
            "DOS RIOS",
            "SAN JOSE O PIZOTE",
            "UPALA",
            "YOLILLAL"
         ]
      },
      {
         "region":"BAGACES",
         "comunas":[
            "BAGACES",
            "LA FORTUNA",
            "MOGOTE",
            "RIO NARANJO"
         ]
      },
      {
         "region":"LIBERIA",
         "comunas":[
            "LIBERIA",
            "CAÑAS DULCES",
            "CURUBANDE",
            "MAYORGA",
            "NACASCOLO"
         ]
      },
      {
         "region":"TILARAN",
         "comunas":[
            "ARENAL",
            "LIBANO",
            "QUEBRADA GRANDE",
            "SANTA ROSA",
            "TIERRAS MORENAS",
            "TILARAN",
            "TRONADORA"
         ]
      },
      {
         "region":"LA CRUZ",
         "comunas":[
            "SANTA CECILIA",
            "LA CRUZ",
            "LA GARITA",
            "SANTA ELENA"
         ]
      },
      {
         "region":"CARRILLO",
         "comunas":[
            "BELEN",
            "FILADELFIA",
            "PALMIRA",
            "SARDINAL"
         ]
      },
      {
         "region":"HOJANCHA",
         "comunas":[
            "HOJANCHA",
            "HUACAS",
            "MONTE ROMO",
            "PUERTO CARRILLO"
         ]
      },
      {
         "region":"NANDAYURE",
         "comunas":[
            "BEJUCO",
            "CARMONA",
            "PORVENIR",
            "SAN PABLO",
            "SANTA RITA",
            "ZAPOTAL"
         ]
      },
      {
         "region":"NICOYA",
         "comunas":[
            "BELEN DE NOSARITA",
            "MANSION",
            "NICOYA",
            "NOSARA",
            "QUEBRADA HONDA",
            "SAMARA",
            "SAN ANTONIO"
         ]
      },
      {
         "region":"SANTA CRUZ",
         "comunas":[
            "BOLSON",
            "CABO VELAS",
            "CARTAGENA",
            "CUAJINIQUIL",
            "DIRIA",
            "SANTA CRUZ",
            "TAMARINDO",
            "TEMPATE",
            "VEINTISIETE DE ABRIL"
         ]
      },
      {
         "region":"BUENOS AIRES",
         "comunas":[
            "BIOLLEY",
            "BORUCA",
            "BRUNKA",
            "BUENOS AIRES",
            "CHANGUENA",
            "COLINAS",
            "PILAS",
            "POTRERO GRANDE",
            "VOLCAN"
         ]
      },
      {
         "region":"CORREDORES",
         "comunas":[
            "CANOAS",
            "CORREDOR",
            "LA CUESTA",
            "LAUREL"
         ]
      },
      {
         "region":"COTO BRUS",
         "comunas":[
            "AGUA BUENA",
            "GUTIERREZ BRAUN",
            "LIMONCITO",
            "PITTIER",
            "SABALITO",
            "SAN VITO"
         ]
      },
      {
         "region":"GOLFITO",
         "comunas":[
            "GOLFITO",
            "GUAYCARA",
            "PAVON",
            "PUERTO JIMENEZ"
         ]
      },
      {
         "region":"GUACIMO",
         "comunas":[
            "DUACARI",
            "GUACIMO",
            "MERCEDES",
            "POCORA",
            "RIO JIMENEZ"
         ]
      },
      {
         "region":"LEON CORTES",
         "comunas":[
            "LLANO BONITO",
            "SAN ANDRES",
            "SAN ANTONIO",
            "SAN ISIDRO",
            "SAN PABLO",
            "SANTA CRUZ"
         ]
      },
      {
         "region":"LIMON",
         "comunas":[
            "LIMON",
            "MATAMA",
            "RIO BLANCO",
            "VALLE DE LA ESTRELLA"
         ]
      },
      {
         "region":"MATINA",
         "comunas":[
            "BATAN",
            "CARRANDI",
            "MATINA"
         ]
      },
      {
         "region":"OSA",
         "comunas":[
            "BAHIA BALLENA",
            "BAHIA DRAKE",
            "PALMAR",
            "PIEDRAS BLANCAS",
            "PUERTO CORTES",
            "SIERPE"
         ]
      },
      {
         "region":"PARRITA",
         "comunas":[
            "PARRITA"
         ]
      },
      {
         "region":"PEREZ ZELEDON",
         "comunas":[
            "BARU",
            "CAJON",
            "DANIEL FLORES",
            "EL GENERAL",
            "LA AMISTAD",
            "PARAMO",
            "PEJIBAYE",
            "PLATANARES",
            "RIO NUEVO",
            "RIVAS",
            "SAN ISIDRO DE EL GENERAL",
            "SAN PEDRO"
         ]
      },
      {
         "region":"POCOCI",
         "comunas":[
            "CARIARI",
            "COLORADO",
            "GUAPILES",
            "JIMENEZ",
            "LA COLONIA",
            "RITA",
            "ROXANA"
         ]
      },
      {
         "region":"QUEPOS",
         "comunas":[
            "NARANJITO",
            "QUEPOS",
            "SAVEGRE"
         ]
      },
      {
         "region":"SIQUIRRES",
         "comunas":[
            "EL CAIRO",
            "FLORIDA",
            "GERMANIA",
            "LA ALEGRIA",
            "PACUARITO",
            "SIQUIRRES"
         ]
      },
      {
         "region":"TALAMANCA",
         "comunas":[
            "BRATSI",
            "CAHUITA",
            "SIXAOLA",
            "TELIRE"
         ]
      },
      {
         "region":"TARRAZU",
         "comunas":[
            "SAN CARLOS",
            "SAN LORENZO",
            "SAN MARCOS"
         ]
      }
    ]
  },
  {
   "pais":"PE",
   "datos":
   [
     
      {
         "region":"Chachapoyas",
         "ciudad":"Amazonas",
         "comunas":[
            "Chachapoyas",
            "Asunción",
            "Balsas",
            "Cheto",
            "Chiliquin",
            "Chuquibamba",
            "Granada",
            "Huancas",
            "La Jalca",
            "Leimebamba",
            "Levanto",
            "Magdalena",
            "Mariscal Castilla",
            "Molinopampa",
            "Montevideo",
            "Olleros",
            "Quinjalca",
            "San Francisco de Daguas",
            "San Isidro de Maino",
            "Soloco",
            "Sonche",
         ]
      },
      {
         "region":"Bagua",
         "ciudad":"Amazonas",
         "comunas":[
            "Bagua",
            "Aramango",
            "Copallin",
            "El Parco",
            "Imaza",
            "La Peca",
         ]
      },
      {
         "region":"Bongará",
         "ciudad":"Amazonas",
         "comunas":[
            "Jumbilla",
            "Chisquilla",
            "Churuja",
            "Corosha",
            "Cuispes",
            "Florida",
            "Jazan",
            "Recta",
            "San Carlos",
            "Shipasbamba",
            "Valera",
            "Yambrasbamba",
         ]
      },
      {
         "region":"Condorcanqui",
         "ciudad":"Amazonas",
         "comunas":[
            "Nieva",
            "El Cenepa",
            "Río Santiago",
         ]
      },
      {
         "region":"Luya",
         "ciudad":"Amazonas",
         "comunas":[
            "Lamud",
            "Camporredondo",
            "Cocabamba",
            "Colcamar",
            "Conila",
            "Inguilpata",
            "Longuita",
            "Lonya Chico",
            "Luya",
            "Luya Viejo",
            "María",
            "Ocalli",
            "Ocumal",
            "Pisuquia",
            "Providencia",
            "San Cristóbal",
            "San Francisco de Yeso",
            "San Jerónimo",
            "San Juan de Lopecancha",
            "Santa Catalina",
            "Santo Tomas",
            "Tingo",
            "Trita",
         ]
      },
      {
         "region":"Rodríguez de Mendoza",
         "ciudad":"Amazonas",
         "comunas":[
            "San Nicolás",
            "Chirimoto",
            "Cochamal",
            "Huambo",
            "Limabamba",
            "Longar",
            "Mariscal Benavides",
            "Milpuc",
            "Omia",
            "Santa Rosa",
            "Totora",
            "Vista Alegre",
         ]
      },
      {
         "region":"Utcubamba",
         "ciudad":"Amazonas",
         "comunas":[
            "Bagua Grande",
            "Cajaruro",
            "Cumba",
            "El Milagro",
            "Jamalca",
            "Lonya Grande",
            "Yamon",
         ]
      },
      {
         "region":"Huaraz",
         "ciudad":"Áncash",
         "comunas":[
            "Huaraz",
            "Cochabamba",
            "Colcabamba",
            "Huanchay",
            "Independencia",
            "Jangas",
            "La Libertad",
            "Olleros",
            "Pampas Grande",
            "Pariacoto",
            "Pira",
            "Tarica",
         ]
      },
      {
         "region":"Aija",
         "ciudad":"Áncash",
         "comunas":[
            "Aija",
            "Coris",
            "Huacllan",
            "La Merced",
            "Succha",
         ]
      },
      {
         "region":"Antonio Raymondi",
         "ciudad":"Áncash",
         "comunas":[
            "Llamellin",
            "Aczo",
            "Chaccho",
            "Chingas",
            "Mirgas",
            "San Juan de Rontoy",
         ]
      },
      {
         "region":"Asunción",
         "ciudad":"Áncash",
         "comunas":[
            "Chacas",
            "Acochaca",
         ]
      },
      {
         "region":"Bolognesi",
         "ciudad":"Áncash",
         "comunas":[
            "Chiquian",
            "Abelardo Pardo Lezameta",
            "Antonio Raymondi",
            "Aquia",
            "Cajacay",
            "Canis",
            "Colquioc",
            "Huallanca",
            "Huasta",
            "Huayllacayan",
            "La Primavera",
            "Mangas",
            "Pacllon",
            "San Miguel de Corpanqui",
            "Ticllos",
         ]
      },
      {
         "region":"Carhuaz",
         "ciudad":"Áncash",
         "comunas":[
            "Carhuaz",
            "Acopampa",
            "Amashca",
            "Anta",
            "Ataquero",
            "Marcara",
            "Pariahuanca",
            "San Miguel de Aco",
            "Shilla",
            "Tinco",
            "Yungar",
         ]
      },
      {
         "region":"Carlos Fermín Fitzcarrald",
         "ciudad":"Áncash",
         "comunas":[
            "San Luis",
            "San Nicolás",
            "Yauya",
         ]
      },
      {
         "region":"Casma",
         "ciudad":"Áncash",
         "comunas":[
            "Casma",
            "Buena Vista Alta",
            "Comandante Noel",
            "Yautan",
         ]
      },
      {
         "region":"Corongo",
         "ciudad":"Áncash",
         "comunas":[
            "Corongo",
            "Aco",
            "Bambas",
            "Cusca",
            "La Pampa",
            "Yanac",
            "Yupan",
         ]
      },
      {
         "region":"Huari",
         "ciudad":"Áncash",
         "comunas":[
            "Huari",
            "Anra",
            "Cajay",
            "Chavin de Huantar",
            "Huacachi",
            "Huacchis",
            "Huachis",
            "Huantar",
            "Masin",
            "Paucas",
            "Ponto",
            "Rahuapampa",
            "Rapayan",
            "San Marcos",
            "San Pedro de Chana",
            "Uco",
         ]
      },
      {
         "region":"Huarmey",
         "ciudad":"Áncash",
         "comunas":[
            "Huarmey",
            "Cochapeti",
            "Culebras",
            "Huayan",
            "Malvas",
         ]
      },
      {
         "region":"Huaylas",
         "ciudad":"Áncash",
         "comunas":[
            "Caraz",
            "Huallanca",
            "Huata",
            "Huaylas",
            "Mato",
            "Pamparomas",
            "Pueblo Libre",
            "Santa Cruz",
            "Santo Toribio",
            "Yuracmarca",
         ]
      },
      {
         "region":"Mariscal Luzuriaga",
         "ciudad":"Áncash",
         "comunas":[
            "Piscobamba",
            "Casca",
            "Eleazar Guzmán Barron",
            "Fidel Olivas Escudero",
            "Llama",
            "Llumpa",
            "Lucma",
            "Musga",
         ]
      },
      {
         "region":"Ocros",
         "ciudad":"Áncash",
         "comunas":[
            "Ocros",
            "Acas",
            "Cajamarquilla",
            "Carhuapampa",
            "Cochas",
            "Congas",
            "Llipa",
            "San Cristóbal de Rajan",
            "San Pedro",
            "Santiago de Chilcas",
         ]
      },
      {
         "region":"Pallasca",
         "ciudad":"Áncash",
         "comunas":[
            "Cabana",
            "Bolognesi",
            "Conchucos",
            "Huacaschuque",
            "Huandoval",
            "Lacabamba",
            "Llapo",
            "Pallasca",
            "Pampas",
            "Santa Rosa",
            "Tauca",
         ]
      },
      {
         "region":"Pomabamba",
         "ciudad":"Áncash",
         "comunas":[
            "Pomabamba",
            "Huayllan",
            "Parobamba",
            "Quinuabamba",
         ]
      },
      {
         "region":"Recuay",
         "ciudad":"Áncash",
         "comunas":[
            "Recuay",
            "Catac",
            "Cotaparaco",
            "Huayllapampa",
            "Llacllin",
            "Marca",
            "Pampas Chico",
            "Pararin",
            "Tapacocha",
            "Ticapampa",
         ]
      },
      {
         "region":"Santa",
         "ciudad":"Áncash",
         "comunas":[
            "Chimbote",
            "Cáceres del Perú",
            "Coishco",
            "Macate",
            "Moro",
            "Nepeña",
            "Samanco",
            "Santa",
            "Nuevo Chimbote",
         ]
      },
      {
         "region":"Sihuas",
         "ciudad":"Áncash",
         "comunas":[
            "Sihuas",
            "Acobamba",
            "Alfonso Ugarte",
            "Cashapampa",
            "Chingalpo",
            "Huayllabamba",
            "Quiches",
            "Ragash",
            "San Juan",
            "Sicsibamba",
         ]
      },
      {
         "region":"Yungay",
         "ciudad":"Áncash",
         "comunas":[
            "Yungay",
            "Cascapara",
            "Mancos",
            "Matacoto",
            "Quillo",
            "Ranrahirca",
            "Shupluy",
            "Yanama",
         ]
      },
      {
         "region":"Abancay",
         "ciudad":"Apurímac",
         "comunas":[
            "Abancay",
            "Chacoche",
            "Circa",
            "Curahuasi",
            "Huanipaca",
            "Lambrama",
            "Pichirhua",
            "San Pedro de Cachora",
            "Tamburco",
         ]
      },
      {
         "region":"Andahuaylas",
         "ciudad":"Apurímac",
         "comunas":[
            "Andahuaylas",
            "Andarapa",
            "Chiara",
            "Huancarama",
            "Huancaray",
            "Huayana",
            "Kishuara",
            "Pacobamba",
            "Pacucha",
            "Pampachiri",
            "Pomacocha",
            "San Antonio de Cachi",
            "San Jerónimo",
            "San Miguel de Chaccrampa",
            "Santa María de Chicmo",
            "Talavera",
            "Tumay Huaraca",
            "Turpo",
            "Kaquiabamba",
            "José María Arguedas",
         ]
      },
      {
         "region":"Antabamba",
         "ciudad":"Apurímac",
         "comunas":[
            "Antabamba",
            "El Oro",
            "Huaquirca",
            "Juan Espinoza Medrano",
            "Oropesa",
            "Pachaconas",
            "Sabaino",
         ]
      },
      {
         "region":"Aymaraes",
         "ciudad":"Apurímac",
         "comunas":[
            "Chalhuanca",
            "Capaya",
            "Caraybamba",
            "Chapimarca",
            "Colcabamba",
            "Cotaruse",
            "Huayllo",
            "Justo Apu Sahuaraura",
            "Lucre",
            "Pocohuanca",
            "San Juan de Chacña",
            "Sañayca",
            "Soraya",
            "Tapairihua",
            "Tintay",
            "Toraya",
            "Yanaca",
         ]
      },
      {
         "region":"Cotabambas",
         "ciudad":"Apurímac",
         "comunas":[
            "Tambobamba",
            "Cotabambas",
            "Coyllurqui",
            "Haquira",
            "Mara",
            "Challhuahuacho",
         ]
      },
      {
         "region":"Chincheros",
         "ciudad":"Apurímac",
         "comunas":[
            "Chincheros",
            "Anco_Huallo",
            "Cocharcas",
            "Huaccana",
            "Ocobamba",
            "Ongoy",
            "Uranmarca",
            "Ranracancha",
         ]
      },
      {
         "region":"Grau",
         "ciudad":"Apurímac",
         "comunas":[
            "Chuquibambilla",
            "Curpahuasi",
            "Gamarra",
            "Huayllati",
            "Mamara",
            "Micaela Bastidas",
            "Pataypampa",
            "Progreso",
            "San Antonio",
            "Santa Rosa",
            "Turpay",
            "Vilcabamba",
            "Virundo",
            "Curasco",
         ]
      },
      {
         "region":"Arequipa",
         "ciudad":"Arequipa",
         "comunas":[
            "Arequipa",
            "Alto Selva Alegre",
            "Cayma",
            "Cerro Colorado",
            "Characato",
            "Chiguata",
            "Jacobo Hunter",
            "La Joya",
            "Mariano Melgar",
            "Miraflores",
            "Mollebaya",
            "Paucarpata",
            "Pocsi",
            "Polobaya",
            "Quequeña",
            "Sabandia",
            "Sachaca",
            "San Juan de Siguas",
            "San Juan de Tarucani",
            "Santa Isabel de Siguas",
            "Santa Rita de Siguas",
            "Socabaya",
            "Tiabaya",
            "Uchumayo",
            "Vitor",
            "Yanahuara",
            "Yarabamba",
            "Yura",
            "José Luis Bustamante Y Rivero",
         ]
      },
      {
         "region":"Camaná",
         "ciudad":"Arequipa",
         "comunas":[
            "Camaná",
            "José María Quimper",
            "Mariano Nicolás Valcárcel",
            "Mariscal Cáceres",
            "Nicolás de Pierola",
            "Ocoña",
            "Quilca",
            "Samuel Pastor",
         ]
      },
      {
         "region":"Caravelí",
         "ciudad":"Arequipa",
         "comunas":[
            "Caravelí",
            "Acarí",
            "Atico",
            "Atiquipa",
            "Bella Unión",
            "Cahuacho",
            "Chala",
            "Chaparra",
            "Huanuhuanu",
            "Jaqui",
            "Lomas",
            "Quicacha",
            "Yauca",
         ]
      },
      {
         "region":"Castilla",
         "ciudad":"Arequipa",
         "comunas":[
            "Aplao",
            "Andagua",
            "Ayo",
            "Chachas",
            "Chilcaymarca",
            "Choco",
            "Huancarqui",
            "Machaguay",
            "Orcopampa",
            "Pampacolca",
            "Tipan",
            "Uñon",
            "Uraca",
            "Viraco",
         ]
      },
      {
         "region":"Caylloma",
         "ciudad":"Arequipa",
         "comunas":[
            "Chivay",
            "Achoma",
            "Cabanaconde",
            "Callalli",
            "Caylloma",
            "Coporaque",
            "Huambo",
            "Huanca",
            "Ichupampa",
            "Lari",
            "Lluta",
            "Maca",
            "Madrigal",
            "San Antonio de Chuca",
            "Sibayo",
            "Tapay",
            "Tisco",
            "Tuti",
            "Yanque",
            "Majes",
         ]
      },
      {
         "region":"Condesuyos",
         "ciudad":"Arequipa",
         "comunas":[
            "Chuquibamba",
            "Andaray",
            "Cayarani",
            "Chichas",
            "Iray",
            "Río Grande",
            "Salamanca",
            "Yanaquihua",
         ]
      },
      {
         "region":"Islay",
         "ciudad":"Arequipa",
         "comunas":[
            "Mollendo",
            "Cocachacra",
            "Dean Valdivia",
            "Islay",
            "Mejia",
            "Punta de Bombón",
         ]
      },
      {
         "region":"La Uniòn",
         "ciudad":"Arequipa",
         "comunas":[
            "Cotahuasi",
            "Alca",
            "Charcana",
            "Huaynacotas",
            "Pampamarca",
            "Puyca",
            "Quechualla",
            "Sayla",
            "Tauria",
            "Tomepampa",
            "Toro",
         ]
      },
      {
         "region":"Huamanga",
         "ciudad":"Ayacucho",
         "comunas":[
            "Ayacucho",
            "Acocro",
            "Acos Vinchos",
            "Carmen Alto",
            "Chiara",
            "Ocros",
            "Pacaycasa",
            "Quinua",
            "San José de Ticllas",
            "San Juan Bautista",
            "Santiago de Pischa",
            "Socos",
            "Tambillo",
            "Vinchos",
            "Jesús Nazareno",
            "Andrés Avelino Cáceres Dorregaray",
         ]
      },
      {
         "region":"Cangallo",
         "ciudad":"Ayacucho",
         "comunas":[
            "Cangallo",
            "Chuschi",
            "Los Morochucos",
            "María Parado de Bellido",
            "Paras",
            "Totos",
         ]
      },
      {
         "region":"Huanca Sancos",
         "ciudad":"Ayacucho",
         "comunas":[
            "Sancos",
            "Carapo",
            "Sacsamarca",
            "Santiago de Lucanamarca",
         ]
      },
      {
         "region":"Huanta",
         "ciudad":"Ayacucho",
         "comunas":[
            "Huanta",
            "Ayahuanco",
            "Huamanguilla",
            "Iguain",
            "Luricocha",
            "Santillana",
            "Sivia",
            "Llochegua",
            "Canayre",
            "Uchuraccay",
            "Pucacolpa",
         ]
      },
      {
         "region":"La Mar",
         "ciudad":"Ayacucho",
         "comunas":[
            "San Miguel",
            "Anco",
            "Ayna",
            "Chilcas",
            "Chungui",
            "Luis Carranza",
            "Santa Rosa",
            "Tambo",
            "Samugari",
            "Anchihuay",
         ]
      },
      {
         "region":"Lucanas",
         "ciudad":"Ayacucho",
         "comunas":[
            "Puquio",
            "Aucara",
            "Cabana",
            "Carmen Salcedo",
            "Chaviña",
            "Chipao",
            "Huac-Huas",
            "Laramate",
            "Leoncio Prado",
            "Llauta",
            "Lucanas",
            "Ocaña",
            "Otoca",
            "Saisa",
            "San Cristóbal",
            "San Juan",
            "San Pedro",
            "San Pedro de Palco",
            "Sancos",
            "Santa Ana de Huaycahuacho",
            "Santa Lucia",
         ]
      },
      {
         "region":"Parinacochas",
         "ciudad":"Ayacucho",
         "comunas":[
            "Coracora",
            "Chumpi",
            "Coronel Castañeda",
            "Pacapausa",
            "Pullo",
            "Puyusca",
            "San Francisco de Ravacayco",
            "Upahuacho",
         ]
      },
      {
         "region":"Pàucar del Sara Sara",
         "ciudad":"Ayacucho",
         "comunas":[
            "Pausa",
            "Colta",
            "Corculla",
            "Lampa",
            "Marcabamba",
            "Oyolo",
            "Pararca",
            "San Javier de Alpabamba",
            "San José de Ushua",
            "Sara Sara",
         ]
      },
      {
         "region":"Sucre",
         "ciudad":"Ayacucho",
         "comunas":[
            "Querobamba",
            "Belén",
            "Chalcos",
            "Chilcayoc",
            "Huacaña",
            "Morcolla",
            "Paico",
            "San Pedro de Larcay",
            "San Salvador de Quije",
            "Santiago de Paucaray",
            "Soras",
         ]
      },
      {
         "region":"Víctor Fajardo",
         "ciudad":"Ayacucho",
         "comunas":[
            "Huancapi",
            "Alcamenca",
            "Apongo",
            "Asquipata",
            "Canaria",
            "Cayara",
            "Colca",
            "Huamanquiquia",
            "Huancaraylla",
            "Huaya",
            "Sarhua",
            "Vilcanchos",
         ]
      },
      {
         "region":"Vilcas Huamán",
         "ciudad":"Ayacucho",
         "comunas":[
            "Vilcas Huaman",
            "Accomarca",
            "Carhuanca",
            "Concepción",
            "Huambalpa",
            "Independencia",
            "Saurama",
            "Vischongo",
         ]
      },
      {
         "region":"Cajamarca",
         "ciudad":"Cajamarca",
         "comunas":[
            "Cajamarca",
            "Asunción",
            "Chetilla",
            "Cospan",
            "Encañada",
            "Jesús",
            "Llacanora",
            "Los Baños del Inca",
            "Magdalena",
            "Matara",
            "Namora",
            "San Juan",
         ]
      },
      {
         "region":"Cajabamba",
         "ciudad":"Cajamarca",
         "comunas":[
            "Cajabamba",
            "Cachachi",
            "Condebamba",
            "Sitacocha",
         ]
      },
      {
         "region":"Celendín",
         "ciudad":"Cajamarca",
         "comunas":[
            "Celendín",
            "Chumuch",
            "Cortegana",
            "Huasmin",
            "Jorge Chávez",
            "José Gálvez",
            "Miguel Iglesias",
            "Oxamarca",
            "Sorochuco",
            "Sucre",
            "Utco",
            "La Libertad de Pallan",
         ]
      },
      {
         "region":"Chota",
         "ciudad":"Cajamarca",
         "comunas":[
            "Chota",
            "Anguia",
            "Chadin",
            "Chiguirip",
            "Chimban",
            "Choropampa",
            "Cochabamba",
            "Conchan",
            "Huambos",
            "Lajas",
            "Llama",
            "Miracosta",
            "Paccha",
            "Pion",
            "Querocoto",
            "San Juan de Licupis",
            "Tacabamba",
            "Tocmoche",
            "Chalamarca",
         ]
      },
      {
         "region":"Contumazá",
         "ciudad":"Cajamarca",
         "comunas":[
            "Contumaza",
            "Chilete",
            "Cupisnique",
            "Guzmango",
            "San Benito",
            "Santa Cruz de Toledo",
            "Tantarica",
            "Yonan",
         ]
      },
      {
         "region":"Cutervo",
         "ciudad":"Cajamarca",
         "comunas":[
            "Cutervo",
            "Callayuc",
            "Choros",
            "Cujillo",
            "La Ramada",
            "Pimpingos",
            "Querocotillo",
            "San Andrés de Cutervo",
            "San Juan de Cutervo",
            "San Luis de Lucma",
            "Santa Cruz",
            "Santo Domingo de la Capilla",
            "Santo Tomas",
            "Socota",
            "Toribio Casanova",
         ]
      },
      {
         "region":"Hualgayoc",
         "ciudad":"Cajamarca",
         "comunas":[
            "Bambamarca",
            "Chugur",
            "Hualgayoc",
         ]
      },
      {
         "region":"Jaén",
         "ciudad":"Cajamarca",
         "comunas":[
            "Jaén",
            "Bellavista",
            "Chontali",
            "Colasay",
            "Huabal",
            "Las Pirias",
            "Pomahuaca",
            "Pucara",
            "Sallique",
            "San Felipe",
            "San José del Alto",
            "Santa Rosa",
         ]
      },
      {
         "region":"San Ignacio",
         "ciudad":"Cajamarca",
         "comunas":[
            "San Ignacio",
            "Chirinos",
            "Huarango",
            "La Coipa",
            "Namballe",
            "San José de Lourdes",
            "Tabaconas",
         ]
      },
      {
         "region":"San Marcos",
         "ciudad":"Cajamarca",
         "comunas":[
            "Pedro Gálvez",
            "Chancay",
            "Eduardo Villanueva",
            "Gregorio Pita",
            "Ichocan",
            "José Manuel Quiroz",
            "José Sabogal",
         ]
      },
      {
         "region":"San Miguel",
         "ciudad":"Cajamarca",
         "comunas":[
            "San Miguel",
            "Bolívar",
            "Calquis",
            "Catilluc",
            "El Prado",
            "La Florida",
            "Llapa",
            "Nanchoc",
            "Niepos",
            "San Gregorio",
            "San Silvestre de Cochan",
            "Tongod",
            "Unión Agua Blanca",
         ]
      },
      {
         "region":"San Pablo",
         "ciudad":"Cajamarca",
         "comunas":[
            "San Pablo",
            "San Bernardino",
            "San Luis",
            "Tumbaden",
         ]
      },
      {
         "region":"Santa Cruz",
         "ciudad":"Cajamarca",
         "comunas":[
            "Santa Cruz",
            "Andabamba",
            "Catache",
            "Chancaybaños",
            "La Esperanza",
            "Ninabamba",
            "Pulan",
            "Saucepampa",
            "Sexi",
            "Uticyacu",
            "Yauyucan",
         ]
      },
      {
         "region":"Callao",
         "ciudad":"Callao",
         "comunas":[
            "Callao",
            "Bellavista",
            "Carmen de la Legua Reynoso",
            "La Perla",
            "La Punta",
            "Ventanilla",
            "Mi Perú",
         ]
      },
      {
         "region":"Cusco",
         "ciudad":"Cusco",
         "comunas":[
            "Cusco",
            "Ccorca",
            "Poroy",
            "San Jerónimo",
            "San Sebastian",
            "Santiago",
            "Saylla",
            "Wanchaq",
         ]
      },
      {
         "region":"Acomayo",
         "ciudad":"Cusco",
         "comunas":[
            "Acomayo",
            "Acopia",
            "Acos",
            "Mosoc Llacta",
            "Pomacanchi",
            "Rondocan",
            "Sangarara",
         ]
      },
      {
         "region":"Anta",
         "ciudad":"Cusco",
         "comunas":[
            "Anta",
            "Ancahuasi",
            "Cachimayo",
            "Chinchaypujio",
            "Huarocondo",
            "Limatambo",
            "Mollepata",
            "Pucyura",
            "Zurite",
         ]
      },
      {
         "region":"Calca",
         "ciudad":"Cusco",
         "comunas":[
            "Calca",
            "Coya",
            "Lamay",
            "Lares",
            "Pisac",
            "San Salvador",
            "Taray",
            "Yanatile",
         ]
      },
      {
         "region":"Canas",
         "ciudad":"Cusco",
         "comunas":[
            "Yanaoca",
            "Checca",
            "Kunturkanki",
            "Langui",
            "Layo",
            "Pampamarca",
            "Quehue",
            "Tupac Amaru",
         ]
      },
      {
         "region":"Canchis",
         "ciudad":"Cusco",
         "comunas":[
            "Sicuani",
            "Checacupe",
            "Combapata",
            "Marangani",
            "Pitumarca",
            "San Pablo",
            "San Pedro",
            "Tinta",
         ]
      },
      {
         "region":"Chumbivilcas",
         "ciudad":"Cusco",
         "comunas":[
            "Santo Tomas",
            "Capacmarca",
            "Chamaca",
            "Colquemarca",
            "Livitaca",
            "Llusco",
            "Quiñota",
            "Velille",
         ]
      },
      {
         "region":"Espinar",
         "ciudad":"Cusco",
         "comunas":[
            "Espinar",
            "Condoroma",
            "Coporaque",
            "Ocoruro",
            "Pallpata",
            "Pichigua",
            "Suyckutambo",
            "Alto Pichigua",
         ]
      },
      {
         "region":"La Convención",
         "ciudad":"Cusco",
         "comunas":[
            "Santa Ana",
            "Echarate",
            "Huayopata",
            "Maranura",
            "Ocobamba",
            "Quellouno",
            "Kimbiri",
            "Santa Teresa",
            "Vilcabamba",
            "Pichari",
            "Inkawasi",
            "Villa Virgen",
            "Villa Kintiarina",
         ]
      },
      {
         "region":"Paruro",
         "ciudad":"Cusco",
         "comunas":[
            "Paruro",
            "Accha",
            "Ccapi",
            "Colcha",
            "Huanoquite",
            "Omacha",
            "Paccaritambo",
            "Pillpinto",
            "Yaurisque",
         ]
      },
      {
         "region":"Paucartambo",
         "ciudad":"Cusco",
         "comunas":[
            "Paucartambo",
            "Caicay",
            "Challabamba",
            "Colquepata",
            "Huancarani",
            "Kosñipata",
         ]
      },
      {
         "region":"Quispicanchi",
         "ciudad":"Cusco",
         "comunas":[
            "Urcos",
            "Andahuaylillas",
            "Camanti",
            "Ccarhuayo",
            "Ccatca",
            "Cusipata",
            "Huaro",
            "Lucre",
            "Marcapata",
            "Ocongate",
            "Oropesa",
            "Quiquijana",
         ]
      },
      {
         "region":"Urubamba",
         "ciudad":"Cusco",
         "comunas":[
            "Urubamba",
            "Chinchero",
            "Huayllabamba",
            "Machupicchu",
            "Maras",
            "Ollantaytambo",
            "Yucay",
         ]
      },
      {
         "region":"Huancavelica",
         "ciudad":"Huancavelica",
         "comunas":[
            "Huancavelica",
            "Acobambilla",
            "Acoria",
            "Conayca",
            "Cuenca",
            "Huachocolpa",
            "Huayllahuara",
            "Izcuchaca",
            "Laria",
            "Manta",
            "Mariscal Cáceres",
            "Moya",
            "Nuevo Occoro",
            "Palca",
            "Pilchaca",
            "Vilca",
            "Yauli",
            "Ascensión",
            "Huando",
         ]
      },
      {
         "region":"Acobamba",
         "ciudad":"Huancavelica",
         "comunas":[
            "Acobamba",
            "Andabamba",
            "Anta",
            "Caja",
            "Marcas",
            "Paucara",
            "Pomacocha",
            "Rosario",
         ]
      },
      {
         "region":"Angaraes",
         "ciudad":"Huancavelica",
         "comunas":[
            "Lircay",
            "Anchonga",
            "Callanmarca",
            "Ccochaccasa",
            "Chincho",
            "Congalla",
            "Huanca-Huanca",
            "Huayllay Grande",
            "Julcamarca",
            "San Antonio de Antaparco",
            "Santo Tomas de Pata",
            "Secclla",
         ]
      },
      {
         "region":"Castrovirreyna",
         "ciudad":"Huancavelica",
         "comunas":[
            "Castrovirreyna",
            "Arma",
            "Aurahua",
            "Capillas",
            "Chupamarca",
            "Cocas",
            "Huachos",
            "Huamatambo",
            "Mollepampa",
            "San Juan",
            "Santa Ana",
            "Tantara",
            "Ticrapo",
         ]
      },
      {
         "region":"Churcampa",
         "ciudad":"Huancavelica",
         "comunas":[
            "Churcampa",
            "Anco",
            "Chinchihuasi",
            "El Carmen",
            "La Merced",
            "Locroja",
            "Paucarbamba",
            "San Miguel de Mayocc",
            "San Pedro de Coris",
            "Pachamarca",
            "Cosme",
         ]
      },
      {
         "region":"Huaytará",
         "ciudad":"Huancavelica",
         "comunas":[
            "Huaytara",
            "Ayavi",
            "Córdova",
            "Huayacundo Arma",
            "Laramarca",
            "Ocoyo",
            "Pilpichaca",
            "Querco",
            "Quito-Arma",
            "San Antonio de Cusicancha",
            "San Francisco de Sangayaico",
            "San Isidro",
            "Santiago de Chocorvos",
            "Santiago de Quirahuara",
            "Santo Domingo de Capillas",
            "Tambo",
         ]
      },
      {
         "region":"Tayacaja",
         "ciudad":"Huancavelica",
         "comunas":[
            "Pampas",
            "Acostambo",
            "Acraquia",
            "Ahuaycha",
            "Colcabamba",
            "Daniel Hernández",
            "Huachocolpa",
            "Huaribamba",
            "Ñahuimpuquio",
            "Pazos",
            "Quishuar",
            "Salcabamba",
            "Salcahuasi",
            "San Marcos de Rocchac",
            "Surcubamba",
            "Tintay Puncu",
            "Quichuas",
            "Andaymarca",
         ]
      },
      {
         "region":"Huánuco",
         "ciudad":"Huánuco",
         "comunas":[
            "Huanuco",
            "Amarilis",
            "Chinchao",
            "Churubamba",
            "Margos",
            "Quisqui (Kichki)",
            "San Francisco de Cayran",
            "San Pedro de Chaulan",
            "Santa María del Valle",
            "Yarumayo",
            "Pillco Marca",
            "Yacus",
         ]
      },
      {
         "region":"Ambo",
         "ciudad":"Huánuco",
         "comunas":[
            "Ambo",
            "Cayna",
            "Colpas",
            "Conchamarca",
            "Huacar",
            "San Francisco",
            "San Rafael",
            "Tomay Kichwa",
         ]
      },
      {
         "region":"Dos de Mayo",
         "ciudad":"Huánuco",
         "comunas":[
            "La Unión",
            "Chuquis",
            "Marías",
            "Pachas",
            "Quivilla",
            "Ripan",
            "Shunqui",
            "Sillapata",
            "Yanas",
         ]
      },
      {
         "region":"Huacaybamba",
         "ciudad":"Huánuco",
         "comunas":[
            "Huacaybamba",
            "Canchabamba",
            "Cochabamba",
            "Pinra",
         ]
      },
      {
         "region":"Huamalíes",
         "ciudad":"Huánuco",
         "comunas":[
            "Llata",
            "Arancay",
            "Chavín de Pariarca",
            "Jacas Grande",
            "Jircan",
            "Miraflores",
            "Monzón",
            "Punchao",
            "Puños",
            "Singa",
            "Tantamayo",
         ]
      },
      {
         "region":"Leoncio Prado",
         "ciudad":"Huánuco",
         "comunas":[
            "Rupa-Rupa",
            "Daniel Alomía Robles",
            "Hermílio Valdizan",
            "José Crespo y Castillo",
            "Luyando",
            "Mariano Damaso Beraun",
         ]
      },
      {
         "region":"Marañón",
         "ciudad":"Huánuco",
         "comunas":[
            "Huacrachuco",
            "Cholon",
            "San Buenaventura",
         ]
      },
      {
         "region":"Pachitea",
         "ciudad":"Huánuco",
         "comunas":[
            "Panao",
            "Chaglla",
            "Molino",
            "Umari",
         ]
      },
      {
         "region":"Puerto Inca",
         "ciudad":"Huánuco",
         "comunas":[
            "Puerto Inca",
            "Codo del Pozuzo",
            "Honoria",
            "Tournavista",
            "Yuyapichis",
         ]
      },
      {
         "region":"Lauricocha",
         "ciudad":"Huánuco",
         "comunas":[
            "Jesús",
            "Baños",
            "Jivia",
            "Queropalca",
            "Rondos",
            "San Francisco de Asís",
            "San Miguel de Cauri",
         ]
      },
      {
         "region":"Yarowilca",
         "ciudad":"Huánuco",
         "comunas":[
            "Chavinillo",
            "Cahuac",
            "Chacabamba",
            "Aparicio Pomares",
            "Jacas Chico",
            "Obas",
            "Pampamarca",
            "Choras",
         ]
      },
      {
         "region":"Ica",
         "ciudad":"Ica",
         "comunas":[
            "Ica",
            "La Tinguiña",
            "Los Aquijes",
            "Ocucaje",
            "Pachacutec",
            "Parcona",
            "Pueblo Nuevo",
            "Salas",
            "San José de Los Molinos",
            "San Juan Bautista",
            "Santiago",
            "Subtanjalla",
            "Tate",
            "Yauca del Rosario",
         ]
      },
      {
         "region":"Chincha",
         "ciudad":"Ica",
         "comunas":[
            "Chincha Alta",
            "Alto Laran",
            "Chavin",
            "Chincha Baja",
            "El Carmen",
            "Grocio Prado",
            "Pueblo Nuevo",
            "San Juan de Yanac",
            "San Pedro de Huacarpana",
            "Sunampe",
            "Tambo de Mora",
         ]
      },
      {
         "region":"Nazca",
         "ciudad":"Ica",
         "comunas":[
            "Nasca",
            "Changuillo",
            "El Ingenio",
            "Marcona",
            "Vista Alegre",
         ]
      },
      {
         "region":"Palpa",
         "ciudad":"Ica",
         "comunas":[
            "Palpa",
            "Llipata",
            "Río Grande",
            "Santa Cruz",
            "Tibillo",
         ]
      },
      {
         "region":"Pisco",
         "ciudad":"Ica",
         "comunas":[
            "Pisco",
            "Huancano",
            "Humay",
            "Independencia",
            "Paracas",
            "San Andrés",
            "San Clemente",
            "Tupac Amaru Inca",
         ]
      },
      {
         "region":"Huancayo",
         "ciudad":"Junín",
         "comunas":[
            "Huancayo",
            "Carhuacallanga",
            "Chacapampa",
            "Chicche",
            "Chilca",
            "Chongos Alto",
            "Chupuro",
            "Colca",
            "Cullhuas",
            "El Tambo",
            "Huacrapuquio",
            "Hualhuas",
            "Huancan",
            "Huasicancha",
            "Huayucachi",
            "Ingenio",
            "Pariahuanca",
            "Pilcomayo",
            "Pucara",
            "Quichuay",
            "Quilcas",
            "San Agustín",
            "San Jerónimo de Tunan",
            "Saño",
            "Sapallanga",
            "Sicaya",
            "Santo Domingo de Acobamba",
            "Viques",
         ]
      },
      {
         "region":"Concepción",
         "ciudad":"Junín",
         "comunas":[
            "Concepción",
            "Aco",
            "Andamarca",
            "Chambara",
            "Cochas",
            "Comas",
            "Heroínas Toledo",
            "Manzanares",
            "Mariscal Castilla",
            "Matahuasi",
            "Mito",
            "Nueve de Julio",
            "Orcotuna",
            "San José de Quero",
            "Santa Rosa de Ocopa",
         ]
      },
      {
         "region":"Chanchamayo",
         "ciudad":"Junín",
         "comunas":[
            "Chanchamayo",
            "Perene",
            "Pichanaqui",
            "San Luis de Shuaro",
            "San Ramón",
            "Vitoc",
         ]
      },
      {
         "region":"Jauja",
         "ciudad":"Junín",
         "comunas":[
            "Jauja",
            "Acolla",
            "Apata",
            "Ataura",
            "Canchayllo",
            "Curicaca",
            "El Mantaro",
            "Huamali",
            "Huaripampa",
            "Huertas",
            "Janjaillo",
            "Julcán",
            "Leonor Ordóñez",
            "Llocllapampa",
            "Marco",
            "Masma",
            "Masma Chicche",
            "Molinos",
            "Monobamba",
            "Muqui",
            "Muquiyauyo",
            "Paca",
            "Paccha",
            "Pancan",
            "Parco",
            "Pomacancha",
            "Ricran",
            "San Lorenzo",
            "San Pedro de Chunan",
            "Sausa",
            "Sincos",
            "Tunan Marca",
            "Yauli",
            "Yauyos",
         ]
      },
      {
         "region":"Junín",
         "ciudad":"Junín",
         "comunas":[
            "Junin",
            "Carhuamayo",
            "Ondores",
            "Ulcumayo",
         ]
      },
      {
         "region":"Satipo",
         "ciudad":"Junín",
         "comunas":[
            "Satipo",
            "Coviriali",
            "Llaylla",
            "Mazamari",
            "Pampa Hermosa",
            "Pangoa",
            "Río Negro",
            "Río Tambo",
            "Vizcatan del Ene",
         ]
      },
      {
         "region":"Tarma",
         "ciudad":"Junín",
         "comunas":[
            "Tarma",
            "Acobamba",
            "Huaricolca",
            "Huasahuasi",
            "La Unión",
            "Palca",
            "Palcamayo",
            "San Pedro de Cajas",
            "Tapo",
         ]
      },
      {
         "region":"Yauli",
         "ciudad":"Junín",
         "comunas":[
            "La Oroya",
            "Chacapalpa",
            "Huay-Huay",
            "Marcapomacocha",
            "Morococha",
            "Paccha",
            "Santa Bárbara de Carhuacayan",
            "Santa Rosa de Sacco",
            "Suitucancha",
            "Yauli",
         ]
      },
      {
         "region":"Chupaca",
         "ciudad":"Junín",
         "comunas":[
            "Chupaca",
            "Ahuac",
            "Chongos Bajo",
            "Huachac",
            "Huamancaca Chico",
            "San Juan de Iscos",
            "San Juan de Jarpa",
            "Tres de Diciembre",
            "Yanacancha",
         ]
      },
      {
         "region":"Trujillo",
         "ciudad":"La Libertad",
         "comunas":[
            "Trujillo",
            "El Porvenir",
            "Florencia de Mora",
            "Huanchaco",
            "La Esperanza",
            "Laredo",
            "Moche",
            "Poroto",
            "Salaverry",
            "Simbal",
            "Victor Larco Herrera",
         ]
      },
      {
         "region":"Ascope",
         "ciudad":"La Libertad",
         "comunas":[
            "Ascope",
            "Chicama",
            "Chocope",
            "Magdalena de Cao",
            "Paijan",
            "Rázuri",
            "Santiago de Cao",
            "Casa Grande",
         ]
      },
      {
         "region":"Bolívar",
         "ciudad":"La Libertad",
         "comunas":[
            "Bolívar",
            "Bambamarca",
            "Condormarca",
            "Longotea",
            "Uchumarca",
            "Ucuncha",
         ]
      },
      {
         "region":"Chepén",
         "ciudad":"La Libertad",
         "comunas":[
            "Chepen",
            "Pacanga",
            "Pueblo Nuevo",
         ]
      },
      {
         "region":"Julcán",
         "ciudad":"La Libertad",
         "comunas":[
            "Julcan",
            "Calamarca",
            "Carabamba",
            "Huaso",
         ]
      },
      {
         "region":"Otuzco",
         "ciudad":"La Libertad",
         "comunas":[
            "Otuzco",
            "Agallpampa",
            "Charat",
            "Huaranchal",
            "La Cuesta",
            "Mache",
            "Paranday",
            "Salpo",
            "Sinsicap",
            "Usquil",
         ]
      },
      {
         "region":"Pacasmayo",
         "ciudad":"La Libertad",
         "comunas":[
            "San Pedro de Lloc",
            "Guadalupe",
            "Jequetepeque",
            "Pacasmayo",
            "San José",
         ]
      },
      {
         "region":"Pataz",
         "ciudad":"La Libertad",
         "comunas":[
            "Tayabamba",
            "Buldibuyo",
            "Chillia",
            "Huancaspata",
            "Huaylillas",
            "Huayo",
            "Ongon",
            "Parcoy",
            "Pataz",
            "Pias",
            "Santiago de Challas",
            "Taurija",
            "Urpay",
         ]
      },
      {
         "region":"Sánchez Carrión",
         "ciudad":"La Libertad",
         "comunas":[
            "Huamachuco",
            "Chugay",
            "Cochorco",
            "Curgos",
            "Marcabal",
            "Sanagoran",
            "Sarin",
            "Sartimbamba",
         ]
      },
      {
         "region":"Santiago de Chuco",
         "ciudad":"La Libertad",
         "comunas":[
            "Santiago de Chuco",
            "Angasmarca",
            "Cachicadan",
            "Mollebamba",
            "Mollepata",
            "Quiruvilca",
            "Santa Cruz de Chuca",
            "Sitabamba",
         ]
      },
      {
         "region":"Gran Chimú",
         "ciudad":"La Libertad",
         "comunas":[
            "Cascas",
            "Lucma",
            "Marmot",
            "Sayapullo",
         ]
      },
      {
         "region":"Virú",
         "ciudad":"La Libertad",
         "comunas":[
            "Viru",
            "Chao",
            "Guadalupito",
         ]
      },
      {
         "region":"Chiclayo",
         "ciudad":"Lambayeque",
         "comunas":[
            "Chiclayo",
            "Chongoyape",
            "Eten",
            "Eten Puerto",
            "José Leonardo Ortiz",
            "La Victoria",
            "Lagunas",
            "Monsefu",
            "Nueva Arica",
            "Oyotun",
            "Picsi",
            "Pimentel",
            "Reque",
            "Santa Rosa",
            "Saña",
            "Cayalti",
            "Patapo",
            "Pomalca",
            "Pucala",
            "Tuman",
         ]
      },
      {
         "region":"Ferreñafe",
         "ciudad":"Lambayeque",
         "comunas":[
            "Ferreñafe",
            "Cañaris",
            "Incahuasi",
            "Manuel Antonio Mesones Muro",
            "Pitipo",
            "Pueblo Nuevo",
         ]
      },
      {
         "region":"Lambayeque",
         "ciudad":"Lambayeque",
         "comunas":[
            "Lambayeque",
            "Chochope",
            "Illimo",
            "Jayanca",
            "Mochumi",
            "Morrope",
            "Motupe",
            "Olmos",
            "Pacora",
            "Salas",
            "San José",
            "Tucume",
         ]
      },
      {
         "region":"Lima",
         "ciudad":"Lima",
         "alias":[
            "Lima Metropolitana"
         ],
         "comunas":[
            "Lima",
            "Ancón",
            "Ate",
            "Barranco",
            "Breña",
            "Carabayllo",
            "Chaclacayo",
            "Chorrillos",
            "Cieneguilla",
            "Comas",
            "El Agustino",
            "Independencia",
            "Jesús María",
            "La Molina",
            "La Victoria",
            "Lince",
            "Los Olivos",
            "Lurigancho",
            "Lurin",
            "Magdalena del Mar",
            "Pueblo Libre",
            "Miraflores",
            "Pachacamac",
            "Pucusana",
            "Puente Piedra",
            "Punta Hermosa",
            "Punta Negra",
            "Rímac",
            "San Bartolo",
            "San Borja",
            "San Isidro",
            "San Juan de Lurigancho",
            "San Juan de Miraflores",
            "San Luis",
            "San Martín de Porres",
            "San Miguel",
            "Santa Anita",
            "Santa María del Mar",
            "Santa Rosa",
            "Santiago de Surco",
            "Surquillo",
            "Villa El Salvador",
            "Villa María del Triunfo",
         ]
      },
      {
         "region":"Barranca",
         "ciudad":"Lima",
         "comunas":[
            "Barranca",
            "Paramonga",
            "Pativilca",
            "Supe",
            "Supe Puerto",
         ]
      },
      {
         "region":"Cajatambo",
         "ciudad":"Lima",
         "comunas":[
            "Cajatambo",
            "Copa",
            "Gorgor",
            "Huancapon",
            "Manas",
         ]
      },
      {
         "region":"Canta",
         "ciudad":"Lima",
         "comunas":[
            "Canta",
            "Arahuay",
            "Huamantanga",
            "Huaros",
            "Lachaqui",
            "San Buenaventura",
            "Santa Rosa de Quives",
         ]
      },
      {
         "region":"Cañete",
         "ciudad":"Lima",
         "comunas":[
            "San Vicente de Cañete",
            "Asia",
            "Calango",
            "Cerro Azul",
            "Chilca",
            "Coayllo",
            "Imperial",
            "Lunahuana",
            "Mala",
            "Nuevo Imperial",
            "Pacaran",
            "Quilmana",
            "San Antonio",
            "San Luis",
            "Santa Cruz de Flores",
            "Zúñiga",
         ]
      },
      {
         "region":"Huaral",
         "ciudad":"Lima",
         "comunas":[
            "Huaral",
            "Atavillos Alto",
            "Atavillos Bajo",
            "Aucallama",
            "Chancay",
            "Ihuari",
            "Lampian",
            "Pacaraos",
            "San Miguel de Acos",
            "Santa Cruz de Andamarca",
            "Sumbilca",
            "Veintisiete de Noviembre",
         ]
      },
      {
         "region":"Huarochirí",
         "ciudad":"Lima",
         "comunas":[
            "Matucana",
            "Antioquia",
            "Callahuanca",
            "Carampoma",
            "Chicla",
            "Cuenca",
            "Huachupampa",
            "Huanza",
            "Huarochiri",
            "Lahuaytambo",
            "Langa",
            "Laraos",
            "Mariatana",
            "Ricardo Palma",
            "San Andrés de Tupicocha",
            "San Antonio",
            "San Bartolomé",
            "San Damian",
            "San Juan de Iris",
            "San Juan de Tantaranche",
            "San Lorenzo de Quinti",
            "San Mateo",
            "San Mateo de Otao",
            "San Pedro de Casta",
            "San Pedro de Huancayre",
            "Sangallaya",
            "Santa Cruz de Cocachacra",
            "Santa Eulalia",
            "Santiago de Anchucaya",
            "Santiago de Tuna",
            "Santo Domingo de Los Olleros",
            "Surco",
         ]
      },
      {
         "region":"Huaura",
         "ciudad":"Lima",
         "comunas":[
            "Huacho",
            "Ambar",
            "Caleta de Carquin",
            "Checras",
            "Hualmay",
            "Huaura",
            "Leoncio Prado",
            "Paccho",
            "Santa Leonor",
            "Santa María",
            "Sayan",
            "Vegueta",
         ]
      },
      {
         "region":"Oyón",
         "ciudad":"Lima",
         "comunas":[
            "Oyon",
            "Andajes",
            "Caujul",
            "Cochamarca",
            "Navan",
            "Pachangara",
         ]
      },
      {
         "region":"Yauyos",
         "ciudad":"Lima",
         "comunas":[
            "Yauyos",
            "Alis",
            "Allauca",
            "Ayaviri",
            "Azángaro",
            "Cacra",
            "Carania",
            "Catahuasi",
            "Chocos",
            "Cochas",
            "Colonia",
            "Hongos",
            "Huampara",
            "Huancaya",
            "Huangascar",
            "Huantan",
            "Huañec",
            "Laraos",
            "Lincha",
            "Madean",
            "Miraflores",
            "Omas",
            "Putinza",
            "Quinches",
            "Quinocay",
            "San Joaquín",
            "San Pedro de Pilas",
            "Tanta",
            "Tauripampa",
            "Tomas",
            "Tupe",
            "Viñac",
            "Vitis",
         ]
      },
      {
         "region":"Maynas",
         "ciudad":"Loreto",
         "comunas":[
            "Iquitos",
            "Alto Nanay",
            "Fernando Lores",
            "Indiana",
            "Las Amazonas",
            "Mazan",
            "Napo",
            "Punchana",
            "Torres Causana",
            "Belén",
            "San Juan Bautista",
         ]
      },
      {
         "region":"Alto Amazonas",
         "ciudad":"Loreto",
         "comunas":[
            "Yurimaguas",
            "Balsapuerto",
            "Jeberos",
            "Lagunas",
            "Santa Cruz",
            "Teniente Cesar López Rojas",
         ]
      },
      {
         "region":"Loreto",
         "ciudad":"Loreto",
         "comunas":[
            "Nauta",
            "Parinari",
            "Tigre",
            "Trompeteros",
            "Urarinas",
         ]
      },
      {
         "region":"Mariscal Ramón Castilla",
         "ciudad":"Loreto",
         "comunas":[
            "Ramón Castilla",
            "Pebas",
            "Yavari",
            "San Pablo",
         ]
      },
      {
         "region":"Requena",
         "ciudad":"Loreto",
         "comunas":[
            "Requena",
            "Alto Tapiche",
            "Capelo",
            "Emilio San Martín",
            "Maquia",
            "Puinahua",
            "Saquena",
            "Soplin",
            "Tapiche",
            "Jenaro Herrera",
            "Yaquerana",
         ]
      },
      {
         "region":"Ucayali",
         "ciudad":"Loreto",
         "comunas":[
            "Contamana",
            "Inahuaya",
            "Padre Márquez",
            "Pampa Hermosa",
            "Sarayacu",
            "Vargas Guerra",
         ]
      },
      {
         "region":"Datem del Marañón",
         "ciudad":"Loreto",
         "comunas":[
            "Barranca",
            "Cahuapanas",
            "Manseriche",
            "Morona",
            "Pastaza",
            "Andoas",
         ]
      },
      {
         "region":"Putumayo",
         "ciudad":"Loreto",
         "comunas":[
            "Putumayo",
            "Rosa Panduro",
            "Teniente Manuel Clavero",
            "Yaguas",
         ]
      },
      {
         "region":"Tambopata",
         "ciudad":"Madre de Dios",
         "comunas":[
            "Tambopata",
            "Inambari",
            "Las Piedras",
            "Laberinto",
         ]
      },
      {
         "region":"Manu",
         "ciudad":"Madre de Dios",
         "comunas":[
            "Manu",
            "Fitzcarrald",
            "Madre de Dios",
            "Huepetuhe",
         ]
      },
      {
         "region":"Tahuamanu",
         "ciudad":"Madre de Dios",
         "comunas":[
            "Iñapari",
            "Iberia",
            "Tahuamanu",
         ]
      },
      {
         "region":"Mariscal Nieto",
         "ciudad":"Moquegua",
         "comunas":[
            "Moquegua",
            "Carumas",
            "Cuchumbaya",
            "Samegua",
            "San Cristóbal",
            "Torata",
         ]
      },
      {
         "region":"General Sánchez Cerro",
         "ciudad":"Moquegua",
         "comunas":[
            "Omate",
            "Chojata",
            "Coalaque",
            "Ichuña",
            "La Capilla",
            "Lloque",
            "Matalaque",
            "Puquina",
            "Quinistaquillas",
            "Ubinas",
            "Yunga",
         ]
      },
      {
         "region":"Ilo",
         "ciudad":"Moquegua",
         "comunas":[
            "Ilo",
            "El Algarrobal",
            "Pacocha",
         ]
      },
      {
         "region":"Pasco",
         "ciudad":"Pasco",
         "comunas":[
            "Chaupimarca",
            "Huachon",
            "Huariaca",
            "Huayllay",
            "Ninacaca",
            "Pallanchacra",
            "Paucartambo",
            "San Francisco de Asís de Yarusyacan",
            "Simon Bolívar",
            "Ticlacayan",
            "Tinyahuarco",
            "Vicco",
            "Yanacancha",
         ]
      },
      {
         "region":"Daniel Alcides Carrión",
         "ciudad":"Pasco",
         "comunas":[
            "Yanahuanca",
            "Chacayan",
            "Goyllarisquizga",
            "Paucar",
            "San Pedro de Pillao",
            "Santa Ana de Tusi",
            "Tapuc",
            "Vilcabamba",
         ]
      },
      {
         "region":"Oxapampa",
         "ciudad":"Pasco",
         "comunas":[
            "Oxapampa",
            "Chontabamba",
            "Huancabamba",
            "Palcazu",
            "Pozuzo",
            "Puerto Bermúdez",
            "Villa Rica",
            "Constitución",
         ]
      },
      {
         "region":"Piura",
         "ciudad":"Piura",
         "comunas":[
            "Piura",
            "Castilla",
            "Atacaos",
            "Cura Mori",
            "El Tallan",
            "La Arena",
            "La Unión",
            "Las Lomas",
            "Tambo Grande",
            "Veintiseis de Octubre",
         ]
      },
      {
         "region":"Ayabaca",
         "ciudad":"Piura",
         "comunas":[
            "Ayabaca",
            "Frias",
            "Jilili",
            "Lagunas",
            "Montero",
            "Pacaipampa",
            "Paimas",
            "Sapillica",
            "Sicchez",
            "Suyo",
         ]
      },
      {
         "region":"Huancabamba",
         "ciudad":"Piura",
         "comunas":[
            "Huancabamba",
            "Canchaque",
            "El Carmen de la Frontera",
            "Huarmaca",
            "Lalaquiz",
            "San Miguel de El Faique",
            "Sondor",
            "Sondorillo",
         ]
      },
      {
         "region":"Morropón",
         "ciudad":"Piura",
         "comunas":[
            "Chulucanas",
            "Buenos Aires",
            "Chalaco",
            "La Matanza",
            "Morropon",
            "Salitral",
            "San Juan de Bigote",
            "Santa Catalina de Mossa",
            "Santo Domingo",
            "Yamango",
         ]
      },
      {
         "region":"Paita",
         "ciudad":"Piura",
         "comunas":[
            "Paita",
            "Amotape",
            "Arenal",
            "Colan",
            "La Huaca",
            "Tamarindo",
            "Vichayal",
         ]
      },
      {
         "region":"Sullana",
         "ciudad":"Piura",
         "comunas":[
            "Sullana",
            "Bellavista",
            "Ignacio Escudero",
            "Lancones",
            "Marcavelica",
            "Miguel Checa",
            "Querecotillo",
            "Salitral",
         ]
      },
      {
         "region":"Talara",
         "ciudad":"Piura",
         "comunas":[
            "Pariñas",
            "El Alto",
            "La Brea",
            "Lobitos",
            "Los Organos",
            "Mancora",
         ]
      },
      {
         "region":"Sechura",
         "ciudad":"Piura",
         "comunas":[
            "Sechura",
            "Bellavista de la Unión",
            "Bernal",
            "Cristo Nos Valga",
            "Vice",
            "Rinconada Llicuar",
         ]
      },
      {
         "region":"Puno",
         "ciudad":"Puno",
         "comunas":[
            "Puno",
            "Acora",
            "Amantani",
            "Atuncolla",
            "Capachica",
            "Chucuito",
            "Coata",
            "Huata",
            "Mañazo",
            "Paucarcolla",
            "Pichacani",
            "Plateria",
            "San Antonio",
            "Tiquillaca",
            "Vilque",
         ]
      },
      {
         "region":"Azángaro",
         "ciudad":"Puno",
         "comunas":[
            "Azángaro",
            "Achaya",
            "Arapa",
            "Asillo",
            "Caminaca",
            "Chupa",
            "José Domingo Choquehuanca",
            "Muñani",
            "Potoni",
            "Saman",
            "San Anton",
            "San José",
            "San Juan de Salinas",
            "Santiago de Pupuja",
            "Tirapata",
         ]
      },
      {
         "region":"Carabaya",
         "ciudad":"Puno",
         "comunas":[
            "Macusani",
            "Ajoyani",
            "Ayapata",
            "Coasa",
            "Corani",
            "Crucero",
            "Ituata",
            "Ollachea",
            "San Gaban",
            "Usicayos",
         ]
      },
      {
         "region":"Chucuito",
         "ciudad":"Puno",
         "comunas":[
            "Juli",
            "Desaguadero",
            "Huacullani",
            "Kelluyo",
            "Pisacoma",
            "Pomata",
            "Zepita",
         ]
      },
      {
         "region":"El Collao",
         "ciudad":"Puno",
         "comunas":[
            "Ilave",
            "Capazo",
            "Pilcuyo",
            "Santa Rosa",
            "Conduriri",
         ]
      },
      {
         "region":"Huancané",
         "ciudad":"Puno",
         "comunas":[
            "Huancane",
            "Cojata",
            "Huatasani",
            "Inchupalla",
            "Pusi",
            "Rosaspata",
            "Taraco",
            "Vilque Chico",
         ]
      },
      {
         "region":"Lampa",
         "ciudad":"Puno",
         "comunas":[
            "Lampa",
            "Cabanilla",
            "Calapuja",
            "Nicasio",
            "Ocuviri",
            "Palca",
            "Paratia",
            "Pucara",
            "Santa Lucia",
            "Vilavila",
         ]
      },
      {
         "region":"Melgar",
         "ciudad":"Puno",
         "comunas":[
            "Ayaviri",
            "Antauta",
            "Cupi",
            "Llalli",
            "Macari",
            "Nuñoa",
            "Orurillo",
            "Santa Rosa",
            "Umachiri",
         ]
      },
      {
         "region":"Moho",
         "ciudad":"Puno",
         "comunas":[
            "Moho",
            "Conima",
            "Huayrapata",
            "Tilali",
         ]
      },
      {
         "region":"San Antonio de Putina",
         "ciudad":"Puno",
         "comunas":[
            "Putina",
            "Ananea",
            "Pedro Vilca Apaza",
            "Quilcapuncu",
            "Sina",
         ]
      },
      {
         "region":"San Román",
         "ciudad":"Puno",
         "comunas":[
            "Juliaca",
            "Cabana",
            "Cabanillas",
            "Caracoto",
         ]
      },
      {
         "region":"Sandia",
         "ciudad":"Puno",
         "comunas":[
            "Sandia",
            "Cuyocuyo",
            "Limbani",
            "Patambuco",
            "Phara",
            "Quiaca",
            "San Juan del Oro",
            "Yanahuaya",
            "Alto Inambari",
            "San Pedro de Putina Punco",
         ]
      },
      {
         "region":"Yunguyo",
         "ciudad":"Puno",
         "comunas":[
            "Yunguyo",
            "Anapia",
            "Copani",
            "Cuturapi",
            "Ollaraya",
            "Tinicachi",
            "Unicachi",
         ]
      },
      {
         "region":"Moyobamba",
         "ciudad":"San Martín",
         "comunas":[
            "Moyobamba",
            "Calzada",
            "Habana",
            "Jepelacio",
            "Soritor",
            "Yantalo",
         ]
      },
      {
         "region":"Bellavista",
         "ciudad":"San Martín",
         "comunas":[
            "Bellavista",
            "Alto Biavo",
            "Bajo Biavo",
            "Huallaga",
            "San Pablo",
            "San Rafael",
         ]
      },
      {
         "region":"El Dorado",
         "ciudad":"San Martín",
         "comunas":[
            "San José de Sisa",
            "Agua Blanca",
            "San Martín",
            "Santa Rosa",
            "Shatoja",
         ]
      },
      {
         "region":"Huallaga",
         "ciudad":"San Martín",
         "comunas":[
            "Saposoa",
            "Alto Saposoa",
            "El Eslabón",
            "Piscoyacu",
            "Sacanche",
            "Tingo de Saposoa",
         ]
      },
      {
         "region":"Lamas",
         "ciudad":"San Martín",
         "comunas":[
            "Lamas",
            "Alonso de Alvarado",
            "Barranquita",
            "Caynarachi",
            "Cuñumbuqui",
            "Pinto Recodo",
            "Rumisapa",
            "San Roque de Cumbaza",
            "Shanao",
            "Tabalosos",
            "Zapatero",
         ]
      },
      {
         "region":"Mariscal Cáceres",
         "ciudad":"San Martín",
         "comunas":[
            "Juanjuí",
            "Campanilla",
            "Huicungo",
            "Pachiza",
            "Pajarillo",
         ]
      },
      {
         "region":"Picota",
         "ciudad":"San Martín",
         "comunas":[
            "Picota",
            "Buenos Aires",
            "Caspisapa",
            "Pilluana",
            "Pucacaca",
            "San Cristóbal",
            "San Hilarión",
            "Shamboyacu",
            "Tingo de Ponasa",
            "Tres Unidos",
         ]
      },
      {
         "region":"Rioja",
         "ciudad":"San Martín",
         "comunas":[
            "Rioja",
            "Awajun",
            "Elías Soplin Vargas",
            "Nueva Cajamarca",
            "Pardo Miguel",
            "Posic",
            "San Fernando",
            "Yorongos",
            "Yuracyacu",
         ]
      },
      {
         "region":"San Martín",
         "ciudad":"San Martín",
         "comunas":[
            "Tarapoto",
            "Alberto Leveau",
            "Cacatachi",
            "Chazuta",
            "Chipurana",
            "El Porvenir",
            "Huimbayoc",
            "Juan Guerra",
            "La Banda de Shilcayo",
            "Morales",
            "Papaplaya",
            "San Antonio",
            "Sauce",
            "Shapaja",
         ]
      },
      {
         "region":"Tocache",
         "ciudad":"San Martín",
         "comunas":[
            "Tocache",
            "Nuevo Progreso",
            "Polvora",
            "Shunte",
            "Uchiza",
         ]
      },
      {
         "region":"Tacna",
         "ciudad":"Tacna",
         "comunas":[
            "Tacna",
            "Alto de la Alianza",
            "Calana",
            "Ciudad Nueva",
            "Inclan",
            "Pachia",
            "Palca",
            "Pocollay",
            "Sama",
            "Coronel Gregorio Albarracín Lanchipa",
         ]
      },
      {
         "region":"Candarave",
         "ciudad":"Tacna",
         "comunas":[
            "Candarave",
            "Cairani",
            "Camilaca",
            "Curibaya",
            "Huanuara",
            "Quilahuani",
         ]
      },
      {
         "region":"Jorge Basadre",
         "ciudad":"Tacna",
         "comunas":[
            "Locumba",
            "Ilabaya",
            "Ite",
         ]
      },
      {
         "region":"Tarata",
         "ciudad":"Tacna",
         "comunas":[
            "Tarata",
            "Héroes Albarracín",
            "Estique",
            "Estique-Pampa",
            "Sitajara",
            "Susapaya",
            "Tarucachi",
            "Ticaco",
         ]
      },
      {
         "region":"Tumbes",
         "ciudad":"Tumbes",
         "comunas":[
            "Tumbes",
            "Corrales",
            "La Cruz",
            "Pampas de Hospital",
            "San Jacinto",
            "San Juan de la Virgen",
         ]
      },
      {
         "region":"Contralmirante Villar",
         "ciudad":"Tumbes",
         "comunas":[
            "Zorritos",
            "Casitas",
            "Canoas de Punta Sal",
         ]
      },
      {
         "region":"Zarumilla",
         "ciudad":"Tumbes",
         "comunas":[
            "Zarumilla",
            "Aguas Verdes",
            "Matapalo",
            "Papayal",
         ]
      },
      {
         "region":"Coronel Portillo",
         "ciudad":"Ucayali",
         "comunas":[
            "Calleria",
            "Campoverde",
            "Iparia",
            "Masisea",
            "Yarinacocha",
            "Nueva Requena",
            "Manantay",
         ]
      },
      {
         "region":"Atalaya",
         "ciudad":"Ucayali",
         "comunas":[
            "Raymondi",
            "Sepahua",
            "Tahuania",
            "Yurua",
         ]
      },
      {
         "region":"Padre Abad",
         "ciudad":"Ucayali",
         "comunas":[
            "Padre Abad",
            "Irazola",
            "Curimana",
            "Neshuya",
            "Alexander Von Humboldt",
         ]
      },
      {
         "region":"Purús",
         "ciudad":"Ucayali",
         "comunas":[
            "Purus",
         ]
      }
 
   ]
   },
];

export default comunas;